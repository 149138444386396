<script>
import {
  Carousel,
  Slide
} from "vue-carousel";
import {
  UserIcon,
  MailIcon,
  KeyIcon,
  ArrowUpIcon,
  HelpCircleIcon,
} from "vue-feather-icons";

import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import referralApi from "@/apis/referralApi";
import storage from '../storage';
import Features from "@/components/features";
import Testimonial from "@/components/testimonial";
import date from "../date";
import Navbar2 from "../components/navbar2.vue";
import relink from "../components/relink.vue"
import back from "../components/back.vue";

/**
 * Index-1
 */
export default {
  data() {
    return {
      referral: undefined,
      referralLoad: 0,
      login: false,
      referralForm: {
        pageNum: 1,
        pageSize: 10
      },
      referralData: {},
      path: ""
    }
  },
  components: {
    Switcher,
    Carousel,
    Slide,
    UserIcon,
    MailIcon,
    KeyIcon,
    ArrowUpIcon,
    Footer,
    HelpCircleIcon,
    Features,
    Testimonial,
    Navbar2,
    relink,
    back
  },
  created() {
    // this.path = location.href.replace("referrel", "auth-signup")
    //获取信息
    referralApi.detail(res => {
      this.referralData = res.data
      this.path = this.path + "?ref=" + res.data.code
    })
    //获取列表
    referralApi.list(this.referralForm, res => {
      this.referral = res.data
      //获取到的数据小于等于全数据时
      if (this.referral.result.length <= this.referralForm.pageSize) this.referralLoad = -1
    })
    //if login
    let userId = storage.localGet("userId")
    if (userId) {
      this.login = true
    }
  },
  methods: {
    loadmore(page) {
      //开始加载
      this.referralLoad = 1
      this.referralForm.pageNum = page
      //获取项目列表
      referralApi.list(this.referralForm, res => {
        this.referralLoad = 0
        Object.assign(this.referral, res.data)
      })
    },
    //用于手机加载
    loadmore2() {
      this.referralForm.pageNum++
      //开始加载
      this.referralLoad = 1
      let pages = Math.ceil(this.referral.totalCount / this.referralForm.pageSize)
      //获取项目列表
      if (pages >= this.referralForm.pageNum) {
        referralApi.mine(this.referralForm, res => {
          res.data.result.unshift(...this.referral.result)
          Object.assign(this.referral, res.data)
          this.referralLoad = 0
          if (pages <= this.referralForm.pageNum) this.referralLoad = -1 //结束加载
        })
      } else {
        this.referralLoad = -1;
      }
    },
    //类型选择
    select(val) {
      this.referralForm.type = val
      this.loadmore(this.referralForm.pageNum)
    },
    //重提交
    reSub(id) {
      referralApi.reSubmission(id, res => {
        if (res.code != 200) {
          this.$message.error(res.msg)
        } else {
          this.loadmore(this.referralForm.pageNum)
        }
      })
    },
    copysuccess() {
      this.$message.success("Copied")
    },
    copyerror() {
      this.$message.success("Fail")
    }
  },
  filters: {
    getDate(data) {
      return date.getDate(data)
    },
    getTime(data) {
      return date.getTime(data)
    }
  }
};
</script>

<template>
  <div>
    <back></back>
    <section style="margin-top: 20px;">
      <div class="container" style="background-color: whitesmoke;padding: 20px;border-radius: 10px;">
        <h3>Referral Program</h3>
        <p style="font-size: 18px;">Share the website to others.You'll get 3% bonus based on their initial investment amount.
          For example,if someone invest a $200 plan with your referral code, you'll get $6 for free.
          See the link below,
          copy-paste link and share it with your friends, earn a massive commission.</p>
        <!--					<p style="margin-top: 20px;">Note: The free experience plan does not participate in Referral Program.</p>-->
      </div>
    </section>

    <relink></relink>
    <!-- 项目列表 -->
    <section class="section pt-3">
      <div style="background-color: whitesmoke;padding: 20px;border-radius: 10px;margin-bottom: 20px;padding-top: 10px;">
        <div style="padding: 10xp 0;">
          <div>
            <h4>Referral</h4>
          </div>
          <el-row>
            <el-col :span="10">
              <h6 class="text-primary">Your Email</h6>
            </el-col>
            <el-col :span="14">
	          <span>
			        {{ referralData.email }}
            </span>
            </el-col>
          </el-row>
        </div>

        <div style="padding: 10xp 0;">
          <el-row>
            <el-col :span="10">
              <h6 class="text-primary">Referral Code</h6>
            </el-col>
            <el-col :span="14">
			      <span>
			      <!-- {{ referral.result.length }} -->
			        {{ referralData.code }}
			      </span>
            </el-col>
          </el-row>
        </div>
      </div>
      <!--end container-->
      <div class="container mt-5">
        <el-row :gutter="20">
          <el-col :span="22" :xs="24">
            <h4>Referral list</h4>
          </el-col>
        </el-row>

        <el-row v-if="referral==undefined" v-loading="referral==undefined"
                element-loading-background="rgba(0, 0, 0, 0)">
        </el-row>
        <el-row :gutter="20" v-else>
          <!--end col-->
          <el-col :span="24" :xs="24">
            <div class="table-responsive bg-white shadow rounded mt-4">
              <table class="table mb-0 table-center" v-loading="referralLoad==1">
                <thead class="bg-light">
                <tr>
                  <th scope="col" class="text-center">Email</th>
                  <th scope="col" class="text-center">Join Date</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="it in referral.result" :key="it.id">
                  <td class="text-center">
                    <a class="forum-title text-primary font-weight-bold">
                      {{ it.email }}</a>
                  </td>
                  <td class="text-center small">{{ it.createdAt | getTime }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </el-col>
          <!--end col-->
        </el-row>
        <!--end row-->
        <div class="mt-3 window">
          <div style="display: flex;justify-content: center;">
            <b-pagination v-if="referral!=undefined" :disabled="referralLoad == 1" size="lg"
                          v-model="referralForm.pageNum" @change="loadmore" :total-rows="referral.totalCount"
                          :per-page="referralForm.pageSize"
                          first-text="First"
                          prev-text="Prev"
                          next-text="Next"
                          last-text="Last">
            </b-pagination>
          </div>
        </div>
        <div class="mt-3 phone">
          <div v-if="referral!=undefined" class="d-grid gap-2">
            <b-button v-if="referralLoad == 0" block class="btn btn-primary" @click="loadmore2">Load More
            </b-button>
            <b-button v-else-if="referralLoad == 1" block class="btn btn-primary disabled">Loading...
            </b-button>
            <b-button v-else block class="btn btn-primary disabled">No More</b-button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
